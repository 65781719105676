/* Floating Button component */
import React, { useEffect, useState } from 'react';
import './FloatingButton.css';
import { motion, AnimatePresence } from "framer-motion"

function FloatingButton({ onClick, isHidden }) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // If the button is not hidden, start the animation
    if (!isHidden) {
      setAnimate(true);
      // Remove the animation class after the animation completes
      const timer = setTimeout(() => setAnimate(false), 300); // match this with the animation duration
      return () => clearTimeout(timer);
    }
  }, [isHidden]);

  return (
    <AnimatePresence>
      <div className={`floating-button ${isHidden ? 'hidden' : animate ? 'animate-in' : ''}`} onClick={onClick}>
        <button>앱 출시 알림 받기</button>
      </div>
    </AnimatePresence>
  );
}

export default FloatingButton;